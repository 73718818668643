<template>
  <div>
    <router-view v-slot="{ Component }">
      <div v-if="isLoading">
        <TheLoader />
      </div>

      <component
        v-show="!isLoading"
        v-if="Component"
        :is="Component"
        ref="currentComponent"
      />
    </router-view>
  </div>
</template>

<script>
import "public/adifier/wp-includes/css/admin-bar.min.css";
import "public/adifier/wp-content/themes/adifier/css/author.css";

import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import TheLoader from "modules/ui/TheLoader.vue";

import rusLocale from "./locales/rus.json";

export default {
  name: "Profile",
  components: {
    TheLoader,
  },
  setup() {
    // не использует redirect в router.js при переходе через router.push
    const router = useRouter();

    const { mergeLocaleMessage } = useI18n();
    mergeLocaleMessage("rus", rusLocale);

    if (router.currentRoute._value.name == "UserProfile") {
      router.push({ name: "ProfileDashboard" });
    }

    return {
      isLoading: ref(false),
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("user/updateUserInfo");

    if (this.$refs.currentComponent && this.$refs.currentComponent.loadData) {
      await this.$refs.currentComponent.loadData();
    }

    this.isLoading = false;
  },
  async updated() {
    this.isLoading = true;

    if (this.$refs.currentComponent.loadData) {
      await this.$refs.currentComponent.loadData();
    }

    this.isLoading = false;
  },
};
</script>
